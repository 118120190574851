import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { MessageDialogeComponent } from '../../shared/components/message-dialoge/message-dialoge.component';
import { Message } from '../../shared/_models/Info.model';
import { User } from '../../shared/_models/User.modal';
import { PatientDashboardService } from './patient-dashboard.service';
import { UserDataService } from './user-data.service';

@Injectable({ providedIn: 'root' })
export class InfoService {
  public user = new BehaviorSubject<User>(null);
  public postings = new BehaviorSubject<any>(null);

  constructor(
    private userDataService: UserDataService,
    private patientDashboardServoce: PatientDashboardService,
    private modal: NgbModal,
    private toastr: ToastrService
  ) {}

  initInfo() {
    this.setUser();
  }

  setUser() {
    this.userDataService.user.subscribe((user) => this.user.next(user));
  }

  autoSet() {}

  showMessage(err: any, title: string = ''): Promise<boolean> {
    let message: Message = {
      title: title ? `Error ${title}` : 'ERROR',
      text: err?.error?.error || 'UnknownError',
      style: 'danger',
    };
    return this.openMessageModel(message);
  }

  openMessageModel(message: Message): Promise<boolean> {
    const modelRef = this.modal.open(MessageDialogeComponent, {
      size: 'md',
      keyboard: false,
      backdrop: 'static',
    });
    modelRef.componentInstance.message = message;

    return modelRef.result;
  }

  showErrorToast(err: any) {
    this.toastr.error(err.error.error, err.statusText);
  }

  showSuccessToast() {
    this.toastr.success('Success');
  }
}
